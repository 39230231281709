import * as React from 'react';
import { useEffect, useRef } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { userShortcodes } from '../../lib/userShortcodes';
import styled, { ThemeProvider } from 'styled-components';
import { ContentWrapper } from '../content/Content';
import { HeroContent, HeroWrapper } from '../content/Hero';
import CloseIcon from '../../images/x_bold.svg';
import { useLayoutContext } from '../../contexts/LayoutContext';
import { Crumb } from '../Breadcrumbs';
import { useMountEffect } from '../../hooks/useMountEffect';
import { ReadingProgress } from '../ReadingProgress';
import { screenSize } from '../../styles/ScreenSize';
import { PageContext } from 'gatsby/internal';
import { ReadMorePageQueryQuery } from '../../generated/graphql-types';
import SEO from '../seo';
import { useLocaleContext } from '../../contexts/LocaleContext';
import { getLocalizedLink } from '../LocalizedLink';

const Main = styled.main<ReadMore>`
	display: flex;
	flex-flow: column;
	align-items: center;
	min-height: calc(100vh - var(--nav-bar-height));
	background-color: ${(p) => p.theme.bgColor};

	h1,
	h2 {
		color: inherit;
		border: none;
		font-size: 45px;
		line-height: 1;

		${screenSize.sm} {
			font-size: 32px;
			line-height: 1.1;
		}
	}

	${HeroContent}, ${ContentWrapper} {
		max-width: 800px;
		width: 100%;
		padding: 0 var(--padding);
	}

	${HeroWrapper} {
		display: flex;
		justify-content: center;
		width: 100%;
		min-height: 0;
		margin-top: var(--content-top-margin);
		padding: 0;
		flex: 0;

		${screenSize.sm} {
			margin-top: 18px;

			h2 {
				margin-right: 40px;
			}
		}
	}

	${ContentWrapper} {
		padding-bottom: var(--content-bottom-margin);
	}
`;

const CloseButton = styled.button`
	position: fixed;
	top: 65px;
	right: var(--padding);
	width: 40px;
	cursor: pointer;
	background: none;
	border: none;
	margin: 0;
	padding: 0;

	:hover,
	:focus {
		svg path {
			fill: #fff;
		}
	}
`;

interface ReadMore {
	bgColor?: string;
}

interface ReadMoreLocationState {
	parent: string;
}

export default function ReadMoreTemplate({
	data,
	pageContext,
	location,
	navigate,
}: PageProps<ReadMorePageQueryQuery, PageContext, ReadMoreLocationState>) {
	const { setBreadcrumbs, setFooterHidden } = useLayoutContext();
	const { setPageTranslations } = useLocaleContext();
	const targetMain = useRef<HTMLDivElement>(null);
	const { parentPage, translations } = pageContext;
	const { locale, defaultLocale } = useLocaleContext();

	useEffect(() => {
		setPageTranslations(translations);
	}, []);

	const body = data.mdx?.body;
	const frontmatter = data.mdx?.frontmatter;

	const { background, parent, title } = frontmatter || { background: '', parent: '', title: '' };

	const currentTheme = {
		bgColor: background,
		hideFooter: true,
	};

	useEffect(() => {
		setFooterHidden(true);
		return () => {
			setFooterHidden(false);
		};
	}, []);

	useMountEffect(() => {
		if (!parentPage) return;

		const crumbs: Crumb[] = [
			{
				href: `/${parentPage.frontmatter.slug}`,
				title: parentPage.frontmatter.title,
			},
			{
				href: '',
				title: title,
			},
		];

		setBreadcrumbs(crumbs);
	});

	function navigateBack() {
		const hasParentHistory = location.state?.parent;

		if (hasParentHistory) {
			history.back();
		} else {
			const path = getLocalizedLink(`/${parent}`, locale, defaultLocale);
			navigate(path, { replace: true }); //TODO: Locale aware navigation
		}
	}

	return (
		<ThemeProvider theme={currentTheme}>
			<SEO title={frontmatter?.title}></SEO>
			<ReadingProgress target={targetMain} />
			<Main ref={targetMain}>
				<MDXProvider components={userShortcodes}>
					<MDXRenderer>{body || ''}</MDXRenderer>
				</MDXProvider>
			</Main>

			<CloseButton onClick={navigateBack} aria-label="close">
				<CloseIcon aria-hidden="true" />
			</CloseButton>
		</ThemeProvider>
	);
}

export const pageQuery = graphql`
	query ReadMorePageQuery($basename: String!, $language: String!) {
		mdx(fields: { basename: { eq: $basename }, locale: { eq: $language } }) {
			id
			body
			frontmatter {
				slug
				title
				background
				group
				parent
			}
		}
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
